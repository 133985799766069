/*
 * graylog-plugin-teams - Graylog Microsoft Teams plugin
 * Copyright © 2021 Shohei Hida
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */
import React from 'react';
import PropTypes from 'prop-types';
import ReadOnlyFormGroup from './components/ReadOnlyFormGroup';

const TeamsNotificationDetails = ({ notification }) => {
  return (
    <>
      <ReadOnlyFormGroup label="Webhook URL" value={notification.config.webhook_url} />
      <ReadOnlyFormGroup label="Graylog URL" value={notification.config.graylog_url} />
      <ReadOnlyFormGroup label="Color" value={notification.config.color} />
      <ReadOnlyFormGroup label="Card title" value={notification.config.card_title} />
      <ReadOnlyFormGroup label="Card body" value={notification.config.message} />
      { notification.config.proxy_url
        ? <ReadOnlyFormGroup label="Proxy URL" value={notification.config.proxy_url} />
        : null}
    </>
  );
};

TeamsNotificationDetails.propTypes = {
  notification: PropTypes.object.isRequired,
};

export default TeamsNotificationDetails;
